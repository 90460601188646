import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import BaseLayout from '../components/templates/BaseLayout';
import PrimaryHeader from '../components/atoms/typography/PrimaryHeader';
import SubHeader from '../components/atoms/typography/SubHeader';
import Spacer from '../components/atoms/utility/Spacer';
import SEO from '../components/atoms/utility/seo';
import JumboPanel from '../components/atoms/JumboPanel';
import MeetTheTeam from '../components/organisms/MeetTheTeam';
import { RequiredGatsbyImagePropType } from '../utils/special-prop-types/required-gatsby-image-prop-type';

function AboutPageHeroContent() {
  return (
    <div className="-mt-[0%] sm:mt-[0%] lg:mt-[10%] text-center sm:text-left">
      <PrimaryHeader
        headerText="We Care About Community"
        className="text-5xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-[72px] leading-[1.3] md:leading-[1.4] xl:leading-[1.3] mb-2 sm:mb-4 lg:mb-4 text-white capitalize max-w-[350px] sm:max-w-sm md:max-w-md lg:max-w-md xl:max-w-lg mx-auto lg:mr-auto sm:ml-0 lg:ml-0 font-light"
      />
      <SubHeader
        headerText="Meet the team, and discover our passion for community."
        className="mb-4 md:mb-4 lg:mb-4 text-white mx-auto font-light max-w-[300px] sm:max-w-sm lg:max-w-md sm:mx-0"
      />
    </div>
  );
}

export default function TeamPage({
  data: {
    visionImg, missionImg, aboutHeroImg, aboutMobileHeroImg,
  },
}) {
  const jumboPanelOne = {
    image: {
      imageSrc: visionImg,
      imageAlt: 'People playing basketball in a community',
    },
    headline: {
      spanText: 'Our Vision',
      spanColor: '#6A67FF',
      headerText: '',
      headlineClassName: 'mb-4 text-center lg:text-left',
    },
    paragraph: {
      paragraphText:
      'Our vision at Flockx is to foster vibrant, '
      + 'sustainable communities through personalized connections. '
      + 'We aim to be the Social GPS that guides people to the '
      + 'communities and experiences they love, seamlessly '
      + 'linking local businesses and individuals with their '
      + 'ideal counterparts, enriching lives and strengthening the social fabric.',

      paragraphClassName:
        'max-w-xl text-center lg:text-left text-grey-100',
    },
  };

  const jumboPanelTwo = {
    image: {
      imageSrc: missionImg,
      imageAlt: 'People watching the sunset in a canyon.',
    },
    headline: {
      spanText: 'The Mission',
      spanColor: '#FF7575',
      headerText: '',
      headlineClassName: 'mb-4 text-center lg:text-left',
    },
    paragraph: {
      paragraphText:
        'Our mission is to organize local community activity by '
        + 'connecting members with local events and shared experiences, '
        + 'answering the essential question "What\'s happening '
        + 'in my community?" We aim to deepen your ties '
        + ' with the local communities (flocks) you love.' ,

         
        
      paragraphClassName:
        'max-w-lg xl:max-w-[600px] text-center lg:text-left xl:-mr-8 text-grey-100',
    },
  };

  return (
    <BaseLayout
      heroContent={AboutPageHeroContent}
      mobileHeroImg={aboutMobileHeroImg}
      heroImg={aboutHeroImg}
    >
      <SEO
        title="Meet the Team Behind the Real-Time Innovation"
        keywords={[
          'mobile app',
          'team members',
          'community',
          'real-time',
          'local events',
          'vision',
          'mission statement',
          'inspiration',
        ]}
        description="Get to know the passionate team behind the real-time innovation of the flockx Community App. Meet the individuals dedicated to creating a supportive community for all users to connect, participate in activities, and grow together."
      />
      <Spacer sizeY={1} />
      <Spacer className="block md:hidden" />
      <JumboPanel content={jumboPanelOne} isTeamPage />
      <Spacer />
      <Spacer className="block md:hidden" />
      <JumboPanel
        content={jumboPanelTwo}
        imageSide="left"
        isTeamPage
      />
      <Spacer />
      <Spacer className="block md:hidden" />
      <MeetTheTeam />
    </BaseLayout>
  );
}

TeamPage.propTypes = {
  data: PropTypes.shape({
    visionImg: RequiredGatsbyImagePropType,
    missionImg: RequiredGatsbyImagePropType,
    aboutHeroImg: RequiredGatsbyImagePropType,
    aboutMobileHeroImg: RequiredGatsbyImagePropType,
  }).isRequired,
};

export const query = graphql`
    query AboutPageQuery {
        visionImg: file(name: { eq: "vision-img" }) {
            childImageSharp {
                gatsbyImageData(width: 889)
            }
        }
        missionImg: file(name: { eq: "mission-img" }) {
            childImageSharp {
                gatsbyImageData(width: 889)
            }
        }
        aboutHeroImg: file(name: { eq: "about-hero" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
        aboutMobileHeroImg: file(name: { eq: "about-mobile-hero" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
    }
`;
