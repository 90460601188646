import React from 'react';
import useQuest from '../../../utils/hooks/swr/useQuest';

export default function ExperienceFetcher({ questID }) {
  const { quest, isLoading, isError } = useQuest(questID);

  if (isError) return <div>failed to load</div>;

  if (isLoading) return <div>loading...</div>;

  if (quest) return <ExperienceVideo quest={quest} />;
}

function ExperienceVideo({ quest }) {
  const videoID = quest.data.relationships.video.data.id;
  const [video] = quest.included.filter(
    (videoResource) => videoID === videoResource.id
  );
  const videoUrl = video.attributes.url;

  return (
    <div className='p-1 rounded-3xl bg-secondary-red inline-block w-full md:w-[320px] lg:w-[350px]'>
      <video
        className='rounded-3xl'
        muted
        autoPlay
        playsInline
        loop
        controls
        width='100%'
      >
        <source
          src={videoUrl.replace('http://', 'https://')}
          type='video/mp4'
        />
      </video>
    </div>
  );
}
